// @ts-nocheck
window.jQuery = jQuery
window.$ = jQuery

jQuery(document).ready(() => {
  "use strict"

  const $frame = jQuery('.frame'),
        $wrap = $frame.parent();

  $frame.sly({
    horizontal: 1,
    itemNav: 'basic',
    mouseDragging: 1,
    smart: 1,
    releaseSwing: 1,
    scrollBy: 1,
    speed: 300,
    elasticBounds: 1,
    easing: 'easeOutExpo',
    dragHandle: 1,
    dynamicHandle: 1,
    clickBar: 1,
    activateOn: 'click',
    activatePageOn: 'click',
    scrollBar: $wrap.find('.scrollbar'),
    nextPage: $wrap.find('.nextPage'),
    prevPage: $wrap.find('.prevPage'),
  });
})
